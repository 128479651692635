import React, { useState } from 'react';
import { Typography, Button, Collapse, Box } from '@mui/material';

const articles = [
  {
    title: 'Brand New Maktab Program',
    content: ` We're excited to announce a brand new Maktab Program which started July 5th, 2023. This program is intended for the youth, but all ages are welcome.

We'll go over the fundamentals of Islamic knowledge, including prayer and how to read the Quran, and we'll go at a pace that works for everyone in the group. In addition to the educational activities, kids can participate in fun sports like basketball, ping-pong, and more. There are separate instructors for boys and girls, ensuring a comfortable and conducive learning environment for all.

The timing of the program is from 5:30 PM - 7:30 PM on weekdays, making it a perfect afterschool activity. Contact the imam while seats are still available !!! We look forward to seeing you there inshaAllah !`,
    pdf1: "/forms/BaitulRegistration2023.pdf",
    pdf2: "/forms/MaktabFlyer2023.pdf"
  },
  {
    title: 'Article 2',
    content: 'This is a snippet of the second article...'
  },
  
];

function Articles() {
  const [expanded, setExpanded] = useState(null);

  return (
    <Box className="articles-section" sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', backgroundColor: '#1f5156', minHeight: '100vh', width: '100%', padding: '20px' }}>
      {articles.map((article, index) => (
        <Box 
          className="article-card" 
          key={index} 
          sx={{ backgroundColor: '#f5f5dc', marginBottom: '60px', maxWidth: '80%', marginTop: '20px', width: '100%', padding: '40px', borderRadius: '10px' }}
        >
          <Typography 
            variant="h5" 
            component="h2" 
            className="article-title"
            sx={{ color:'#1f5156', fontWeight: 'bold', fontSize: '2.5em'}}
          >
            {article.title}
          </Typography>
          <Collapse in={expanded === index} collapsedSize={40}>
            <Typography 
              variant="body2" 
              component="p" 
              className="article-content"
              sx={{ color: '#1f5156', lineHeight: '1.5', marginTop: '20px', paddingLeft: '20px'  }}
            >
              {article.content}
            </Typography>
            {expanded === index && (
              <Box sx={{ marginTop: '20px' }}>
                <a href={article.pdf1} download>Download Baitul Registration Form</a>
                <br/>
                <a href={article.pdf2} download>Download Maktab Flyer</a>
              </Box>
            )}
          </Collapse>
          <Button 
            variant="text" 
            onClick={() => setExpanded(expanded === index ? null : index)}
            sx={{ marginTop: '20px', textTransform: 'none', textDecoration: 'underline', color: '#1f5156' }}
          >
            {expanded === index ? 'Read Less' : 'Read More'}
          </Button>
        </Box>
      ))}
    </Box>
  );
}

export default Articles;
