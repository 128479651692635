import React from 'react';
import { Typography, Grid, Box } from '@mui/material';

function Footer() {
  return (
    <Box 
      sx={{ 
        backgroundColor: '#1f5156', 
        color: 'white', 
        padding: '20px', 
        marginTop: 'auto',
      }}
    >
      <Grid container spacing={3}>
        <Grid item xs={4}>
          <Typography variant="body1">
            Address: 3223 61st St, Queens, NY 11377<br/>
            Hours: Open ⋅ Closes 11 PM<br/>
            Phone: (646) 436-9611
          </Typography>
        </Grid>
        <Grid item xs={4}>
          <Typography variant="body1" align="center">
            © 2023 Baitul Ateeq
          </Typography>
        </Grid>
        <Grid item xs={4}>
          <Typography variant="body1">
            Follow Us:<br/>
            Facebook: [Link]<br/>
            Twitter: [Link]<br/>
            Instagram: [Link]
          </Typography>
        </Grid>
      </Grid>
    </Box>
  );
}

export default Footer;
