import React from "react";
import "./HomeHeroSection.css";
import mosqueImage from "../images/baitularc.png";

const HomeHeroSection = () => {
  return (
    <div className="home-hero-section">
      <div className="home-text-section">
      <h1 className="welcome-text">Welcome to Baitul Ateeq</h1>
        <h1 className="bold-text">A Newly-Renovated Islamic Center</h1>
        <p>
          Dedicated to Teaching The Quran And Sunnah
        </p>
        <p className="gray-text">
          Ibn Umar reported: The Messenger of Allah, peace and blessings be upon him, said, “Verily, when a servant stands to pray, his sins are placed on top of his head and shoulders. Every time he bows or prostrates, they fall away from him.”
        </p>
        <p className="source-text">
          Source: Ṣaḥīḥ Ibn Ḥibbān 1769
        </p>
        <button className="learn-more-button">Learn More</button>
      </div>
      <div className="home-image-section">
        <img src={mosqueImage} alt="Baitul Ateeq Mosque" className="mosque-image" />
      </div>
    </div>
  );
};

export default HomeHeroSection;
