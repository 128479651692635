import React from "react";
import NavBar from "../components/NavBar";
import { Container, Typography, Button, Divider } from "@mui/material";
import "./About.css";

const About = () => {
  return (
    <>
      
      <div className="about-section">
        <Container maxWidth="md">
          <Typography variant="h4" className="section-title">
            About Baitul Ateeq Islamic Center
          </Typography>
          <Typography variant="body1" className="intro-text">
            Baitul Ateeq Islamic Center is a community-oriented Islamic center located in the heart of Woodside, at the intersection of 61st Street and Northern Blvd. We have been proudly serving the community for over 9 years, fostering Islamic values, providing education, and enabling Muslims to pray in congregation (Jammat).
          </Typography>

          <Divider />

          <div className="program-section">
            <Typography variant="h5" className="program-title">
              Our Programs
            </Typography>
            <Typography variant="body1" className="program-text">
              At Baitul Ateeq, we offer a range of programs to cater to the diverse needs of our community. One of our flagship initiatives is the Maktab Program, an educational program designed to empower our youth with the fundamentals of Allah's teachings. Through this program, we strive to instill a strong Islamic foundation, moral values, and character development in our future generations.
            </Typography>
            <Typography variant="body1" className="program-text">
              Additionally, we organize regular community events, workshops, and lectures by renowned scholars to promote Islamic knowledge, spirituality, and community bonding. We welcome individuals of all ages and backgrounds to participate and benefit from these enlightening programs.
            </Typography>
          </div>

          <Divider />

          <div className="why-masjid-section">
            <Typography variant="h5" className="masjid-title">
              Why Masjids Exist
            </Typography>
            <Typography variant="body1" className="masjid-text">
              Masjids serve as vital spiritual and community centers for Muslims around the world. They are not just places of worship but also hubs for learning, socializing, and community development. Masjids provide a sacred space for Muslims to come together, pray in congregation, seek knowledge, and find solace.
            </Typography>
            <Typography variant="body1" className="masjid-text">
              Moreover, masjids play a crucial role in fostering unity, promoting brotherhood and sisterhood, and strengthening the bonds of the Muslim community. They serve as platforms for organizing community initiatives, supporting charitable endeavors, and providing assistance to those in need.
            </Typography>
          </div>

          <Divider />

          <div className="cta-section">
            <Typography variant="h5" className="cta-title">
              Join Our Community
            </Typography>
            <Typography variant="body1" className="cta-text">
              We invite you to be a part of the vibrant Baitul Ateeq Islamic Center community. Explore our website to learn more about our programs, upcoming events, and ways to get involved. Visit us and experience the warmth and spirituality that our center offers.
            </Typography>
            <Button variant="contained" className="cta-button">
              Learn More
            </Button>
          </div>
        </Container>
      </div>
    </>
  );
};

export default About;
