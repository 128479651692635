import React from 'react';
import { Typography, TextField, Button, Box } from '@mui/material';

function Contact() {
  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', backgroundColor: '#1f5156', minHeight: '100vh', width: '100%', padding: '20px', color: 'white' }}>
      <Typography variant="h4" component="h1" sx={{ marginBottom: '20px' }}>
        Contact Us
      </Typography>
      <Typography variant="body1" sx={{ marginBottom: '40px', maxWidth: '80%' }}>
        Address: 3223 61st St, Queens, NY 11377<br/>
        Hours: Open ⋅ Closes 11 PM<br/>
        Phone: (646) 436-9611<br/>
        <br/>
        This website and the Baitul Ateeq masjid are a work in progress, and we would love to improve with your feedback. Please use the form below to submit your suggestions.
      </Typography>
      <form noValidate autoComplete="off" sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', maxWidth: '80%', width: '100%' }}>
        <TextField label="Name" variant="outlined" sx={{ marginBottom: '20px', width: '100%', backgroundColor: '#f5f5dc' }}/>
        <TextField label="Email" variant="outlined" sx={{ marginBottom: '20px', width: '100%', backgroundColor: '#f5f5dc' }}/>
        <TextField label="Feedback/Suggestions" variant="outlined" multiline rows={4} sx={{ marginBottom: '20px', width: '100%', backgroundColor: '#f5f5dc' }}/>
        <Button variant="contained" color="primary" type="submit">
          Submit
        </Button>
      </form>
    </Box>
  );
}

export default Contact;
