import React from 'react';
import { Box } from '@mui/material';
import { Router } from '@reach/router';
import NavBar from './components/NavBar';
import Home from './Pages/Home';
import About from './Pages/About';
import Articles from './Pages/Articles';
import Contact from './Pages/Contact';
import Footer from './components/Footer';

function App() {
  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', minHeight: '100vh' }}>
      <NavBar />
      <Router>
        <Home path="/" />
        <About path="/about" />
        <Articles path="/blog" />
        <Contact path="/contact" />
      </Router>
      <Footer />
    </Box>
  );
}

export default App;