import React from "react";
import NavBar from "../components/NavBar";
import HomeHeroSection from "../components/HomeHeroSection";
import "./Home.css";

const Home = () => {
  return (
    <>
   

      <div className="home">

      <div className="hero-section">
            
          <HomeHeroSection />

          <div className="iframe-container">
          <iframe
            title="Baitul Ateeq Mosque"
            src="https://www.livemosque.live/mosque.html?mosque=baitul_ateeq_masjid__123__muslim_center"
            frameBorder="0"
            allowFullScreen
          ></iframe>
        </div>
        </div>
       
        
       
      </div>
    </>
  );
};

export default Home;
